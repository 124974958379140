import { ProfileData } from '@innedit/innedit';
import { PageProps } from 'gatsby';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import Item from '../../../../components/List/Item/Profile';
import CMSView from '../../../../components/View';
import List from '../../../../containers/Admin/List';
import params from '../../../../params/profile/index.json';
import TemplateEspace from '../../../../templates/espace';
import requireEspace, { EspaceProps } from '../../../../utils/requireEspace';
import { UserProps } from '../../../../utils/requireUser';

const PageEspaceProfiles: FC<PageProps & EspaceProps & UserProps> = ({
  espace,
  location,
  user,
}) => {
  const { t } = useTranslation();

  return (
    <TemplateEspace espace={espace} user={user}>
      <CMSView>
        <List
          itemList={Item}
          itemPathnamePrefix={`/espaces/${espace.id}/profils/`}
          model={new ProfileData({ espace, params, espaceId: espace.id })}
          search={location.search}
          title={t('profiles.title')}
          user={user}
        />
      </CMSView>
    </TemplateEspace>
  );
};

export default requireEspace(PageEspaceProfiles);
