import { DocumentType, ProfileType } from '@innedit/innedit-type';
import React, { FC, useEffect, useState } from 'react';

import { ListItemProps } from '../../../../containers/Admin/props';
import ListItem from '../index';

const ListItemProfile: FC<ListItemProps<ProfileType>> = ({
  docId,
  index,
  model,
  onClick,
}) => {
  const [doc, setDoc] = useState<DocumentType<ProfileType>>();

  useEffect(() => {
    const unsub = model.watchById(docId, document => {
      setDoc(document);
    });

    return () => {
      if (unsub) {
        unsub();
      }
    };
  }, [docId, model]);

  if (!doc) {
    return null;
  }

  return (
    <ListItem displayActionId={false} doc={doc} index={index} onClick={onClick}>
      <div className="flex space-x-3">
        <span>{doc.name}</span>
      </div>
    </ListItem>
  );
};

export default ListItemProfile;
